import React, { Fragment } from "react"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    Josh Wheeler
    
    <br />
    
    Systems Engineer @ Cloudflare
    
    <br />
    
    <a href="http://www.joshwheeler.dev"> joshwheeler.dev </a>

  </Fragment>
)
